import {replace} from "lodash";
import {fromPairs, invert, KeyValuePair, map} from "ramda";
import type {MasterDataType} from "../api/masterData";

export enum Stage {
  PUBLIC = "public", // Not a connection stage, but a question stage for pre-connection
  INVITE = "invite",
  ACCEPT = "accept",
  COLLAB = "collaborate",
  CONNECT = "connect",
  CONNECTED = "connected",
  DISCONNECT = "disconnect",
  DISCONNECTED = "disconnected",
  PRIVATE = "private",
}
export const activeConnectionStages = [Stage.INVITE, Stage.ACCEPT, Stage.COLLAB, Stage.CONNECT, Stage.CONNECTED];

export enum SubStage {
  ACCEPT_STARTED = "accept.started",
  ACCEPT_NOT_STARTED = "accept.notStarted",
}

export {Stage as Stages};
export const stageNumber = {
  [Stage.PUBLIC]: 0,
  [Stage.INVITE]: 1,
  [Stage.ACCEPT]: 2,
  [Stage.COLLAB]: 3,
  [Stage.CONNECT]: 4,
  [Stage.CONNECTED]: 5,
  [Stage.DISCONNECT]: 6,
  [Stage.DISCONNECTED]: 7,
  [Stage.PRIVATE]: 8,
};
export function compareStages(stage1: Stage, stage2: Stage) {
  return stageNumber[stage1] - stageNumber[stage2];
}

// TODO: remove this when we convert Stage.CONNECTED to Stage.MANAGE
export const stageExpressionTranslations = {
  ...fromPairs(map((stage) => [stage, stage] as KeyValuePair<string, string>, Object.keys(invert(Stage)))),
  [Stage.CONNECTED]: "manage",
};

export enum ConnectionFields {
  STATUS = "status",
}

export enum ConnectionSearchStatus {
  IN_PROCESS = "process",
  IN_NETWORK = "connected",
  OUT_NETWORK = "public",
  PENDING_INVITES = "pending",
}

export enum ConnectionRole {
  BUYER = "buyer",
  SELLER = "seller",
  BOTH = "both", // Not valid in a connection. For questions.
}

export enum ConnectionSearchShowType {
  ALL = "all",
  MINE = "mine",
}
export {ConnectionRole as ConnectionRoles};

export function toConnectionRole(str: string | undefined): ConnectionRole | undefined {
  switch (str) {
    case undefined:
      return undefined;
    case "buyer":
      return ConnectionRole.BUYER;
    case "seller":
      return ConnectionRole.SELLER;
    case "both":
      return ConnectionRole.BOTH;
    default:
      throw new Error(`${str} is not a valid ConnectionRole`);
  }
}

export enum ConnectionOrigin {
  BUYER_INVITE = "buyer_invite",
  SELLER_INVITE = "seller_invite",
  BUYER_CAMPAIGN = "buyer_campaign",
  CAMPAIGN = "campaign",
  NON_NETWORK = "non_network",
}

export enum ConnectionSearchScope {
  ALL = "all",
  SELLER = "seller",
  BUYER = "buyer",
  BOTH = "both",
  PUBLIC = "public",
  OTHER_SELLER = "other_seller",
  OTHER_BUYER = "other_buyer",
}

export enum ConnectionParty {
  REQUESTING = "Req",
  RESPONDING = "Res",
}

export enum ConnectionCancelAction {
  CANCEL = "cancel",
  DISCONNECT = "disconnect",
}

export enum LeftTabTypes {
  OVERVIEW = "overview",
  PROFILE = "profile",
  RISK = "risk",
  CONTACT_CENTER = "contactCenter",
  DOCUMENT_CENTER = "documents",
  ACTION_PLANS = "ap",
  CAMPAIGNS = "campaign",
  COMM_CENTER = "communication",
  // Kit ones are named "kit-<kitType>" and returned by: kitTabId()
}
export enum AdminConnectionSearch {
  ONLY_ACTIVE_SUPPLIER = "only_active_suppliers",
  ONLY_DELETED = "only_deleted",
  ALL_SUPPLIERS = "all_suppliers",
  ALL_CUSTOMERS = "all_customers",
  ALL = "all",
}

export function kitTabId(kitType: string): string {
  return `kit-${kitType}`;
}

export function isKitTabId(tabId: string): boolean {
  return tabId?.startsWith("kit-");
}

export function getKitTypeOfTabId(tabId: string): string {
  return tabId.substring(4);
}

export function masterDataTabId(masterDataTitle: string): string {
  return replace(`md-${masterDataTitle}`, " ", "-");
}

export function isMasterDataTabId(tabId: string): boolean {
  return Boolean(tabId?.startsWith("md-"));
}

export function getMasterDataOfTabId(tabId: string): string {
  return replace(tabId.substring(3), "-", " ");
}

export function filterMasterData(masterDataTypes: MasterDataType[], tabId: string): MasterDataType | null {
  if (tabId) {
    const filteredValues = masterDataTypes.filter((masterDataType) => {
      return masterDataType.title === tabId;
    });
    if (filteredValues.length === 1) {
      return filteredValues[0];
    }
    return null;
  } else {
    return null;
  }
}

export function getOppositeConnectionRole(role: ConnectionRole) {
  return {
    [ConnectionRole.BUYER]: ConnectionRole.SELLER,
    [ConnectionRole.SELLER]: ConnectionRole.BUYER,
  }[role];
}

export function getOppositeConnectionRoleOrBoth(role: ConnectionRole | undefined): ConnectionRole {
  if (role === undefined) {
    return ConnectionRole.BOTH;
  }
  return {
    [ConnectionRole.BUYER]: ConnectionRole.SELLER,
    [ConnectionRole.SELLER]: ConnectionRole.BUYER,
    [ConnectionRole.BOTH]: ConnectionRole.BOTH,
  }[role];
}

export enum Profile {
  MINE = "mine",
  THEIRS = "theirs",
}
export const oppositeProfile = (profile: Profile) =>
  ({[Profile.MINE]: Profile.THEIRS, [Profile.THEIRS]: Profile.MINE}[profile]);

export enum AcknowledgmentAction {
  ADD = "add",
  CLEAR = "clear",
}
