import type {Dictionary} from "lodash";
import lzString from "lz-string";
import querystring from "querystring";
import {mapObjIndexed} from "ramda";
import {ConnectionDocument} from "../api/search";
import {getConnectionRole} from "../connections";
import {Connection as ConnectionEnum, ConnectionRole, Internal, isValidEnum, Profile, Stage, TaskType} from "../enums";
import {KitType} from "../enums/element";
import {JSONObject} from "./index";

export class UrlUtility {
  public static encode(dataToEncode: JSONObject): string {
    return lzString.compressToEncodedURIComponent(JSON.stringify(dataToEncode));
  }

  public static decode(dataToDecode: string): JSONObject {
    return JSON.parse(lzString.decompressFromEncodedURIComponent(dataToDecode));
  }

  public static generateConnectionUrl(
    baseUrl: string,
    entityId: string,
    connectionId?: string,
    whoseProfile: Profile = Profile.MINE,
    {
      filters,
      elementId,
      readingEntity,
      lvt,
      cct,
    }: {
      filters?: JSONObject;
      elementId?: string;
      readingEntity?: string;
      lvt?: string;
      cct?: string;
    } = {},
  ) {
    return (
      this.baseConnectionUrl(baseUrl, entityId, connectionId, whoseProfile) +
      this.toQueryString({elementId, filters: filters && this.encode(filters), entity: readingEntity, lvt, cct})
    );
  }

  public static generateAcknowledgementUrl(
    baseUrl: string,
    pertainingToEntityId: string,
    connectionId: string,
    elementId: string,
    taskId: string,
    readingEntity?: string,
  ) {
    return UrlUtility.generateTaskBasedUrl(
      baseUrl,
      pertainingToEntityId,
      connectionId,
      elementId,
      taskId,
      "recentlyChanged",
      readingEntity,
    );
  }

  public static generateTaskBasedUrl(
    baseUrl: string,
    pertainingToEntityId: string,
    connectionId: string,
    elementId: string,
    taskId: string,
    action: string,
    readingEntity?: string,
  ) {
    return (
      baseUrl +
      `/connection/${pertainingToEntityId}/${connectionId}/theirs?` +
      `elementId=${elementId}&` +
      `taskId=${taskId}&` +
      `action=${action}&` +
      `entity=${readingEntity}`
    );
  }

  public static generateReminderUrl(
    baseUrl: string,
    entityId: string,
    pertainingToEntityId: string,
    elementId: string,
    reminderId: string,
    connectionId?: string,
    instanceId?: string,
    actionPlan?: string,
    kitType?: KitType,
    kitId?: string,
  ) {
    const whichProfile = entityId === pertainingToEntityId && !actionPlan ? Profile.MINE : Profile.THEIRS;

    if (kitId && kitType) {
      return `${baseUrl}${this.generateKitPath(kitType, kitId)}?reminderId=${reminderId}&action=reminder`;
    }

    let url = "/profile";
    if (connectionId) {
      url = `/connection/${pertainingToEntityId}/${connectionId}/${whichProfile}`;
    }

    if (actionPlan) {
      return `${baseUrl}${url}?&reminderId=${reminderId || ""}&action=reminder&entity=${
        entityId || ""
      }&ap=${actionPlan}$&lvt=ap`;
    } else {
      return (
        `${baseUrl}${url}?elementId=${elementId || ""}&instanceId=${instanceId || ""}` +
        `&reminderId=${reminderId || ""}&action=reminder&entity=${entityId || ""}`
      );
    }
  }

  public static generateConnectionUrlFromSearchResult(
    esSearchResult: ConnectionDocument,
    kitType?: string,
    kitId?: string,
  ): string {
    const isReconnecting = !!esSearchResult.connection?.isReconnecting;
    const hasCampaigns = !!esSearchResult.connection?.hasCampaigns;
    const reasonCode = esSearchResult[ConnectionEnum.SUPPLIER_DISCONNECT_REASON];
    const isRegistered = reasonCode === Internal.DISCONNECT_REASON_REGISTERED;
    let entityToOpen = esSearchResult.connection?.respondingEntityId || esSearchResult.entityId;
    if (
      hasCampaigns &&
      ((esSearchResult.status === Stage.INVITE && !isReconnecting) ||
        (esSearchResult.status === Stage.DISCONNECTED && isRegistered))
    ) {
      entityToOpen = esSearchResult.connection?.requestingEntityId || esSearchResult.entityId;
    }

    let result: string;
    if (entityToOpen === esSearchResult.owningEntityId) {
      result = this.baseConnectionUrl("", esSearchResult.entityId, esSearchResult.connectionId, Profile.MINE);
    } else {
      result = this.baseConnectionUrl("", esSearchResult.entityId, esSearchResult.connectionId, Profile.THEIRS);
    }

    if (kitType && esSearchResult.connectionId) {
      const searchParams = new URLSearchParams();
      searchParams.set("lvt", `kit-${kitType}`);
      if (kitId) {
        searchParams.set("kid", kitId);
      }
      result = `${result}?${searchParams.toString()}`;
    }

    return result;
  }

  public static generateConnectionVueRouterObj(
    entityId: string,
    connectionId: string,
    whichProfile: string,
    filters: JSONObject,
    expandTopic: string,
  ): JSONObject {
    return {
      name: "connection",
      params: {
        entityId,
        connectionId,
        whichProfile,
      },
      query: {
        filters: this.encode(filters),
        expandTopic,
      },
    };
  }

  public static generateTaskUrl(
    baseUrl: string,
    task: any,
    activeEntityId: string,
    withoutEntity?: boolean,
    withoutAp?: boolean,
    activeConnectionId?: string,
  ): string {
    let path = "/profile?";
    let query: any = {};
    let whichProfile = String(activeEntityId) === String(task.pertainingToEntity) ? Profile.MINE : Profile.THEIRS;
    let connectionId = task.connection?._id || activeConnectionId;
    let entityId = task.pertainingToEntity;

    // KIT TASKS
    // go to kit section
    if (task.kitId && task.kitType) {
      if (task.type === TaskType.REMINDER) {
        const reminder = task.data.reminder;
        query = Object.assign(query, {reminderId: reminder ? reminder._id : task.data.reminderId});
      } else if (task.type === TaskType.APPROVAL) {
        query = Object.assign(query, {approvalId: task.data.approval._id});
      } else {
        query = Object.assign(query, {kTaskId: task._id});
      }

      if (task.type === TaskType.WORKFLOW && task.data?.associatedConnection) {
        path = `${this.baseConnectionPath(entityId, task.data?.associatedConnection, whichProfile)}?`;
        query = Object.assign(query, {
          lvt: `kit-${task.kitType}`,
          kTaskId: task._id,
          kid: task.kitId,
          kElementId: task.element?._id,
          kInstanceId: task.data?.instanceId,
          edit: "true",
        });
      } else {
        path = `${this.generateKitPath(task.kitType, task.kitId)}?`;
      }
      return `${baseUrl}${path}${querystring.stringify(
        mapObjIndexed((q) => {
          return q === null || q === undefined || q === "null" || q === "undefined" ? "" : q + "";
        }, query),
      )}`;
    }

    // ACTION PLAN TASKS
    // go to action plan section
    if (task.actionPlan && getConnectionRole(task.connection, activeEntityId) === ConnectionRole.BUYER) {
      whichProfile = Profile.THEIRS;
      if (!withoutAp && !task.element) {
        query = Object.assign(query, {
          ap: task.actionPlan,
          lvt: "ap",
        });
      } else {
        query = Object.assign(query, {
          lvt: "profile",
        });
      }
      if (task.type === TaskType.REMINDER) {
        const reminder = task.data.reminder;
        query = Object.assign(query, {
          reminderId: reminder ? reminder._id : task.data.reminderId,
          action: "reminder",
        });
      } else if (task.type === TaskType.USER_GENERATED) {
        query = Object.assign(query, {
          elementId: task.element && task.element._id,
          instanceId: task.data?.instanceId,
          taskId: task._id,
          action: "userTask",
        });
      } else if (task.type === TaskType.APPROVAL) {
        query = Object.assign(query, {
          approvalId: task.data.approval._id,
          action: "approval",
        });
      }

      // NON - ACTION PLAN TASKS
      // Go to element within profile
    } else {
      if (task.type === TaskType.REMINDER) {
        const reminder = task.data.reminder;
        if (reminder) {
          entityId = reminder.entity;
          connectionId = reminder.connection;
          query = Object.assign(query, {
            elementId: reminder.element,
            instanceId: reminder.instanceId,
            reminderId: reminder._id,
            action: "reminder",
          });
        } else if (task.data.reminderId) {
          query = Object.assign(query, {
            elementId: task.element?._id,
            instanceId: task.instanceId,
            reminderId: task.data.reminderId,
            action: "reminder",
          });
        }
      } else if (task.type === TaskType.APPROVAL && task.data.approval) {
        query = Object.assign(query, {
          elementId: task.data.approval.element,
          taskId: task._id,
          approvalId: task.data.approval._id,
          instanceId: task.data.approval.instanceId,
          action: "approval",
        });
      } else if (task.type === TaskType.ACKNOWLEDGMENT) {
        query = Object.assign(query, {
          elementId: task.element && task.element._id,
          taskId: task._id,
          action: "recentlyChanged",
        });
      } else if (task.type === TaskType.WORKFLOW || task.type === TaskType.VERIFY_IDENTITY) {
        if (!task.element && task.data.emailLogId) {
          query = Object.assign(query, {
            taskId: task._id,
            action: "workflow",
            email: task.data.emailLogId,
            lvt: "communication",
          });
        } else {
          query = Object.assign(query, {
            elementId: task.element && task.element._id,
            instanceId: task.data.instanceId,
            taskId: task._id,
            action: "workflow",
          });
        }
      } else if (task.type === TaskType.USER_GENERATED) {
        query = Object.assign(query, {
          elementId: task.element && task.element._id,
          instanceId: task.data?.instanceId,
          taskId: task._id,
          action: "userTask",
        });
      } else {
        if (task.connection) {
          if (task.element) {
            query = Object.assign(query, {
              elementId: task.element && task.element._id,
              expandReview: task.type === "review",
            });
          }
        }
      }
      if (task.data?.instanceId) {
        query.instanceId = task.data?.instanceId;
      }
    }

    const approval = task.data?.approval;
    const rmndr = task.data?.reminder;
    const kitId = approval?.kitId || rmndr?.kitId;
    const kitType = approval?.kitType || rmndr?.kitType;

    if (task.type === TaskType.ENTITY_ACCESS_REQUEST) {
      path = `/admin/users?edit=${task.data?.userId}`;
    } else if (connectionId && !kitId) {
      path = `/connection/${entityId}/${connectionId}/${whichProfile}?`;
    } else if (kitId && kitType) {
      path = this.generateKitPath(kitId, kitType) + "?";
    }

    if (!task.connection && task.data?.emailLogId) {
      return `${baseUrl}/communication?email=${task.data.emailLogId}&taskId=${task._id}&action=workflow`;
    }

    if (!withoutEntity && task.entity) {
      query = Object.assign(query, {entity: task.entity._id});
    }

    return `${baseUrl}${path}${querystring.stringify(
      mapObjIndexed((q) => {
        return q === null || q === undefined || q === "null" || q === "undefined" ? "" : q + "";
      }, query),
    )}`;
  }

  public static generateTaskMessageUrl(
    baseUrl: string,
    params: {
      taskId: string;
      entityId: string;
      connectionId?: string;
      userEntity?: string;
      kitId?: string;
      kitType?: KitType;
    },
  ): string {
    let path: string;
    if (params.kitType) {
      path = this.generateKitPath(params.kitType, params.kitId!);
    } else {
      path = this.baseConnectionPath(params.entityId, params.connectionId, "theirs");
    }

    const searchParams = new URLSearchParams();
    searchParams.set("action", "taskMessage");
    if (params.kitType) {
      searchParams.set("kTaskId", params.taskId);
    } else {
      searchParams.set("taskId", params.taskId);
    }
    if (params.userEntity) {
      searchParams.set("entity", params.userEntity);
    }

    return `${baseUrl}${path}?${searchParams.toString()}`;
  }

  public static generateActionPlanMessageUrl(
    baseUrl: string,
    actionPlan: {
      actionPlanId: string;
      entityId: string;
      connectionId?: string;
    },
  ): string {
    return (
      `${baseUrl}/connection/${actionPlan.entityId}/${actionPlan.connectionId}/theirs?` +
      `ap=${actionPlan.actionPlanId}&` +
      `lvt=ap&action=actionPlanMessage`
    );
  }

  public static generateTopicUrl(
    baseUrl: string,
    entityId: string,
    connectionId: string,
    topicId: string,
    expandReview: boolean,
  ): string {
    return (
      `${baseUrl}/connection/${entityId}/${connectionId}/theirs?` +
      `elementId=${topicId}&` +
      `lvt=profile` +
      (expandReview ? `&expandReview=true` : "")
    );
  }

  public static generateKitPath(kitType: KitType, kitId: string) {
    return `/kit/${kitType}/${kitId}`;
  }

  public static generateConnectionKitPath(base: string, entityId: string, connection: string, whose: string) {
    return this.baseConnectionUrl(base, entityId, connection, whose);
  }

  public static isUrl(url: string): boolean {
    return (
      url.match(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/i) !== null
    );
  }

  private static toQueryString(obj: {[key: string]: string | undefined}): string {
    const queryString = Object.keys(obj)
      .map<string | undefined>((key: string) => (obj[key] === undefined ? undefined : `${key}=${obj[key]}`))
      .filter((v) => v !== undefined)
      .join("&");
    return queryString === "" ? "" : "?" + queryString;
  }

  private static baseConnectionUrl(baseUrl: string, entityId: string, connectionId: string | undefined, whose: string) {
    return `${baseUrl}${UrlUtility.baseConnectionPath(entityId, connectionId, whose)}`;
  }

  private static baseConnectionPath(entityId: string, connectionId: string | undefined, whose: string) {
    return `/connection/${entityId}/${connectionId || "none"}/${whose}`;
  }
}

export function booleanParam(param: string | undefined | boolean, defaultValue: boolean = false): boolean {
  if (param === undefined || param === null || param === "") {
    return defaultValue;
  }
  if (param === true) {
    return true;
  }
  if (param === false) {
    return false;
  }
  return !(param.toLowerCase() === "false" || param.toLowerCase() === "0");
}

export function enumParam<T>(
  enumType: Dictionary<T>,
  param: string | null | undefined,
  defaultValue?: T,
): T | undefined {
  if (!param) {
    return defaultValue;
  }
  const v = isValidEnum<T>(param, enumType);
  return v || defaultValue;
}

export function numberParam(param: string | undefined | number, defaultValue: number | undefined = 0) {
  if (param === undefined || param === null || param === "") {
    return defaultValue;
  }
  return parseInt(param as string, 10);
}

export function stringListParam(param: string | undefined, delimiter: string = ","): string[] | undefined {
  if (!param) {
    return undefined;
  }
  return param.split(delimiter).map((p) => p.trim());
}
