<template>
  <div
    v-bind="inherit.attributes"
    v-if="questionIsVisible"
    class="question-input-wrap"
    :id="elementId"
    :class="{
      'using-element-actions': !hideActions && useElementActions,
      'show-element-actions': showElementActions === ElementActionsShow.SHOW,
      'show-element-actions-always': showElementActions === ElementActionsShow.ALWAYS_SHOW,
      'has-element-actions': elementActions.length,
      'validation-not-passing': !!externalErrorMessage,
      unverified,
      highlighted: highlighted || popoverShown || isBeingPointedTo,
      'highlight-required': isRequiredAndHighlight,
      'hide-label': hideLabel,
      'view-mode': !editMode,
      [outerClass]: !!outerClass,
    }"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    @focus="onMouseOver"
    @blur="delayedMouseLeave"
    :data-answer-key="questionData.key"
    :data-type="questionData.type"
    :data-view-type="viewComponentTypeWithNoAnswer"
    :tabindex="!editMode ? tabindex : ''"
  >
    <div :id="`wrap_${elementId}`">
      <slot name="questionLabel" :questionData="questionData" :inputId="inputId">
        <question-label
          v-if="!hideLabel"
          :for="inputId"
          :id="`label_${inputId}`"
          :label="questionData.label"
          :required="!isResponderContext && (questionData.required || questionData.requiredForSave)"
          :help-text="questionData.helpText"
          :label-markdown="questionData.labelMarkdown"
          :help-text-markdown="questionData.helpTextMarkdown"
          :external-validation="externalValidation"
          :question-badges="questionBadges"
        />
        <slot name="postQuestionLabel" />
      </slot>
      <template v-if="isLocked">
        <locked
          :edit-mode="editMode"
          :question-data="questionData"
          :modelValue="inputValue"
          @updateInputValidationState="handleUpdateValidationState"
        />
      </template>
      <!-- Components that have a view using a common answer type and edit component (input) -->
      <template v-else-if="!hasNoViewComponent">
        <!-- Read only - nasty duplication but I need a wrap/icon that I don't want to impose on all view components-->
        <div
          v-if="showReadOnlyViewModeWhenInEditMode"
          class="read-only-wrap"
          role="group"
          :aria-labelledby="`label_${inputId}`"
        >
          <slot name="questionAnswerView" :questionData="questionData" :inputId="inputId">
            <component
              :is="viewComponentTypeWithNoAnswer"
              :answer-value="inputValue"
              :question-data="questionData"
              :read-only="readOnly"
            />
            <slot name="viewAnswerBottom" />
          </slot>
        </div>
        <!-- Regular view component -->
        <div
          v-else-if="!editMode && readOnly"
          class="read-only-wrap"
          role="group"
          :aria-labelledby="`label_${inputId}`"
        >
          <slot name="questionAnswerView" :questionData="questionData" :inputId="inputId">
            <component :is="viewComponentTypeWithNoAnswer" :answer-value="inputValue" :question-data="questionData" />
            <slot name="viewAnswerBottom" />
          </slot>
        </div>
        <slot v-else-if="!editMode" name="questionAnswerView" :questionData="questionData" :inputId="inputId">
          <component :is="viewComponentTypeWithNoAnswer" :answer-value="inputValue" :question-data="questionData" />
          <slot name="viewAnswerBottom" />
        </slot>
        <!-- Edit component -->
        <component
          v-else
          v-bind="attrs"
          v-show="editMode"
          :is="inputType"
          :question-data="questionData"
          :input-overrides="inputOverrides"
          :modelValue="inputValue"
          @update:modelValue="handleInputUpdate"
          @updateInputValidationState="handleUpdateValidationState"
          @blur="onBlur"
          @focus="onFocus"
          :tabindex="tabindex"
          :inputId="inputId"
          :read-only="readOnly || showFinalLoadingIndicator"
          ref="questionInput"
          :class="`question-type question-type-${inputType}`"
        />
        <div v-if="showHistory" class="history-last-update">
          <div class="last-updated-by">{{ updatedBy }}</div>
          <div class="last-updated-at">{{ updatedDate }}</div>
        </div>
      </template>
      <!-- Components that handle both the view and edit mode within a single component (likely has unique answer type or no answer) -->
      <component
        v-else
        v-bind="attrs"
        :is="inputType"
        :edit-mode="editMode"
        :question-data="questionData"
        :input-overrides="inputOverrides"
        :modelValue="inputValue"
        @update:modelValue="handleInputUpdate"
        @updateInputValidationState="handleUpdateValidationState"
        @blur="onBlur"
        @focus="onFocus"
        :tabindex="tabindex"
        :inputId="inputId"
        :read-only="readOnly"
        ref="questionInput"
        :class="`question-type question-type-${inputType}`"
      />
    </div>
    <i v-if="showFinalLoadingIndicator" class="fa fa-circle-notch fa-spin loading-indicator" aria-hidden="true" />
    <ellipsis-with-actions
      v-if="showElementActions && !editMode && !hideActions && !showFinalLoadingIndicator"
      class="question-actions"
      :actions="elementActions"
      :element-label="questionData.label"
      @popoverShowChange="onPopoverShowChange"
      @focus="onFocus"
      @blur="
        () => {
          onBlur();
          delayedMouseLeave();
        }
      "
      :override-click="actionClickHandler"
    />
    <font-awesome-icon
      v-if="showRequiredIndicator"
      :icon="['fas', 'asterisk']"
      :class="{'unanswered-required': true, 'must-answer': questionData.requiredForSave}"
    />
    <div v-if="externalErrorMessage" class="invalid-message" :class="{focused: focused}">
      {{ externalErrorMessage }}
    </div>
    <div v-else-if="unverified && !editMode" class="invalid-message" :class="{focused: focused}">
      {{ $t("questions.unverified") }}
    </div>
    <slot name="questionBottom" />
  </div>
</template>

<script lang="ts" setup>
import {setupElementActionSupportForSingleElement} from "@/composables/connections/actions";
import type {RiskOverride} from "@/composables/connections/types";
import {getStore} from "@/composables/get.store";
import {componentGlobalOn, globalEmit} from "@/composables/globalEventBus";
import {injectStrict} from "@/composables/provideInject";
import {QuestionInfoPaneDataKey, ShowingQuestionKeysLoadingIndicatorKey} from "@/composables/questions/injections";
import {
  getAnswerFormatFromQuestionData,
  getHideLabelFromQuestionData,
  getInputTypeComponentFromQuestionData,
  getIsAnsweredComputed,
  getQuestionElementId,
  getUniqueInputId,
  getViewTypeWithNoAnswer,
  isNoAnswerType,
  showReadOnlyViewWithinEdit,
} from "@/composables/questions/inputs";
import {is2FAEnabled, isLabelInputType} from "@/composables/questions/questionData";
import {getParentInstanceId} from "@/composables/questions/questionTree";
import type {ElementAction, InputOverrides, ValidationState, ValidationSummary} from "@/composables/questions/types";
import {AnswerFormats, ElementActionsShow} from "@/composables/questions/types";
import {IsResponderContextInjectionKey} from "@/composables/responder/injections";
import type {VueAttrs} from "@/composables/vuex";
import {getCurrentUserComputed} from "@/composables/vuex";

import globalLogger from "@/logging";
import {getCurrentRoute} from "@/router/util";
import moment from "moment";
import {GroupType, QuestionType} from "pg-isomorphic/enums";
import {check, Permission} from "pg-isomorphic/permissions";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {has, is, isNil, pathOr} from "ramda";
import type {ComputedRef, PropType} from "vue";
import {computed, defineAsyncComponent, defineProps, onBeforeUnmount, ref, toRefs, useAttrs, watch} from "vue";

const logger = globalLogger.getLogger("QuestionInput");

defineOptions({
  inheritAttrs: false,
  components: {
    TextInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/TextInput.vue")),
    MultiTextInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/MultiTextInput.vue")),
    NumberInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/NumberInput.vue")),
    SelectInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/SelectInput.vue")),
    CheckboxInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/CheckboxInput.vue")),
    RadioInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/RadioInput.vue")),
    DatePickerInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/DatePickerInput.vue")),
    QuestionLabel: defineAsyncComponent(() => import("@/components/questions/inputTypes/QuestionLabel.vue")),
    TagsInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/tags/TagsInput.vue")),
    BooleanInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/BooleanInput.vue")),
    CertifyInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/CertifyInput.vue")),
    UserSelectInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/UserSelectInput.vue")),
    MultiLanguageTextInput: defineAsyncComponent(
      () => import("@/components/questions/inputTypes/MultiLanguageTextInput.vue"),
    ),
    FileInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/FileInput.vue")),
    ImageInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/ImageInput.vue")),
    RoleTableInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/RoleTableInput.vue")),
    AddressInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/AddressInput.vue")),
    RoutingInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/RoutingInput.vue")),
    DividerInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/DividerInput.vue")),
    InfoInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/InfoInput.vue")),
    ObscuredInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/ObscuredInput.vue")),
    StopMessageInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/StopMessageInput.vue")),
    BankConnectionSharedTable: defineAsyncComponent(
      () => import("@/components/questions/inputTypes/MasterDataConnectionsTable.vue"),
    ),
    TwoListMultiSelectInput: defineAsyncComponent(
      () => import("@/components/questions/inputTypes/TwoListMultiSelectInput.vue"),
    ),
    Pointer: defineAsyncComponent(() => import("@/components/questions/inputTypes/PointerInput.vue")),
    ContactInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/ContactInput.vue")),
    ButtonInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/ButtonInput.vue")),
    NoAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/NoAnswer.vue")),
    KitEntityTable: defineAsyncComponent(() => import("@/components/shared/questions/kit/KitEntityTable.vue")),
    ViewStringAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewStringAnswer.vue")),
    ViewSelectedOptionsAnswer: defineAsyncComponent(
      () => import("@/components/questions/viewTypes/ViewSelectedOptionsAnswer.vue"),
    ),
    ViewArrayOfStringsAnswer: defineAsyncComponent(
      () => import("@/components/questions/viewTypes/ViewArrayOfStringsAnswer.vue"),
    ),
    ViewTagsAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewTagsAnswer.vue")),
    ViewLocaleObjectAnswer: defineAsyncComponent(
      () => import("@/components/questions/viewTypes/ViewLocaleObjectAnswer.vue"),
    ),
    ViewDateAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewDateAnswer.vue")),
    ViewBooleanAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewBooleanAnswer.vue")),
    ViewCertifyAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewCertifyAnswer.vue")),
    ViewFileAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewFileAnswer.vue")),
    ViewAddressAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewAddressAnswer.vue")),
    ViewRoutingAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewRoutingAnswer.vue")),
    WarningInput: defineAsyncComponent(() => import("./inputTypes/WarningInput.vue")),
    Locked: defineAsyncComponent(() => import("@/components/questions/Locked.vue")),
    RiskScoreLabel: defineAsyncComponent(() => import("@/components/questions/RiskScoreLabel.vue")),
    ViewSelectedContactsAnswer: defineAsyncComponent(
      () => import("@/components/questions/viewTypes/ViewSelectedContactsAnswer.vue"),
    ),
    "ellipsis-with-actions": defineAsyncComponent(() => import("@/components/shared/EllipsisWithActions.vue")),
    SamGovInput: defineAsyncComponent(() => import("@/components/questions/inputTypes/SamGovInput.vue")),
    ViewSamGovAnswer: defineAsyncComponent(() => import("@/components/questions/viewTypes/ViewSamGovAnswer.vue")),
    AssociatedKitsTable: defineAsyncComponent(
      () => import("@/components/shared/questions/kit/AssociatedKitsTable.vue"),
    ),
  },
});

const attrs = useAttrs();

const props = defineProps({
  modelValue: {
    type: [Array, String, Object, Date, Boolean, Number],
    default: undefined,
  },
  questionData: {
    type: Object as PropType<JSONQuestion>,
    default: () => ({}),
  },
  inputOverrides: {
    type: Object as PropType<InputOverrides>,
    default: null,
  },
  editMode: {
    type: Boolean,
    default: true,
  },
  // Overrides questionData.readonly
  readOnly: {
    type: Boolean,
    default: false,
  },
  is2faUnlocked: {
    type: Boolean,
    default: false,
  },
  latestHistory: {
    type: Object as PropType<{updatedAt: string; updatedBy: string}>,
    default: () => ({}),
  },
  tabindex: {
    type: Number,
    default: 0,
  },
  externalValidation: {
    type: String,
    default: "",
  },
  questionBadges: {
    type: Array,
    default: () => [],
  },
  riskOverrideData: {
    type: Object as PropType<RiskOverride>,
    default: () => ({}),
  },
  showUnansweredRequired: {
    type: Boolean,
    default: false,
  },
  externalErrorMessage: {
    type: String,
    default: "",
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  outerClass: {
    type: String,
    default: null,
  },
  unverified: {
    type: Boolean,
    default: false,
  },
  showLoadingIndicator: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  "update:modelValue",
  "updateValidState",
  "updateValidationState",
  "blur",
  "focus",
  "standardElementAction",
]);
// COMPONENT DATA
const {
  questionData,
  modelValue,
  editMode,
  latestHistory,
  is2faUnlocked,
  showUnansweredRequired,
  readOnly,
  hideActions,
  riskOverrideData,
  showLoadingIndicator,
} = toRefs(props);
const highlighted = ref(false);
const isBeingPointedTo = ref(false);
const popoverShown = ref(false);
const questionInput = ref(null);
const focused = ref(false);
const user = getCurrentUserComputed();

const isResponderContext = injectStrict(IsResponderContextInjectionKey, false);
const showingQuestionKeysLoading = injectStrict(ShowingQuestionKeysLoadingIndicatorKey, ref([]));

// forcefully turn of highlighting when going into edit mode
watch(editMode, (isEditing) => {
  if (isEditing && highlighted.value) {
    highlighted.value = false;
  }
});

// COMPUTED
const showFinalLoadingIndicator = computed(
  () => showLoadingIndicator.value || showingQuestionKeysLoading.value.indexOf(questionData.value.key) !== -1,
);
const showReadOnlyViewModeWhenInEditMode = computed(
  () => readOnly.value && showReadOnlyViewWithinEdit(questionData.value, riskOverrideData.value),
);
const inputId = computed(() => getUniqueInputId(questionData.value));
const elementId = computed(() => getQuestionElementId(questionData.value));
const answerType = computed(() => getAnswerFormatFromQuestionData(questionData.value));
const inputType = computed(() => getInputTypeComponentFromQuestionData(questionData.value));
const isAnswered = getIsAnsweredComputed(modelValue, inputType);
const inputValue = computed(() => modelValue.value);
const updatedBy = computed(() => pathOr("", ["updatedBy"], latestHistory.value));
const updatedAt = computed(() => pathOr("", ["updatedAt"], latestHistory.value));
const showHistory = computed(
  () => !editMode.value && !!questionData.value.includeHistory && (updatedBy.value || updatedAt.value),
);
const updatedDate = computed(() => (updatedAt.value ? moment(updatedAt.value).calendar() : ""));
const isNoAnswerTypeComputed = computed(() => isNoAnswerType(questionData.value, modelValue.value));
const viewComponentTypeWithNoAnswer = getViewTypeWithNoAnswer(
  answerType,
  questionData,
  modelValue,
  isNoAnswerTypeComputed,
);
const hasNoViewComponent = computed(() => answerType.value === AnswerFormats.NONE);
const hideLabel = computed(() => {
  return (
    questionData.value.hideLabel ||
    getHideLabelFromQuestionData(questionData.value) ||
    is(Object, questionData.value.label) // object indicates an expression -- not yet ready
  );
});
const instanceId = computed(() => getParentInstanceId(questionData.value));
const {showElementActions, elementActions, useElementActions} = setupElementActionSupportForSingleElement(
  questionData,
  {
    is2faUnlocked,
    instanceId,
  },
);
const auto2FA: ComputedRef<boolean> = computed(() => {
  return Boolean(getCurrentRoute().meta?.auto_2fa && user.value?.twoFactorAuthVerified);
});
const isLocked: ComputedRef<boolean> = computed(() => {
  return (
    (is2FAEnabled(questionData.value) && !is2faUnlocked.value && !auto2FA.value) ||
    questionData.value.type === QuestionType.OBSCURED
  );
});

// separate attributes from listeners
const inherit: ComputedRef<VueAttrs> = computed(() => {
  const onRE = /^on[^a-z]/;
  const attributes = {};
  const listeners = {};

  for (const property in attrs) {
    if (onRE.test(property)) {
      listeners[property] = attrs[property];
    } else {
      attributes[property] = attrs[property];
    }
  }
  return {attributes, listeners};
});
const questionIsVisible = computed(() => {
  return has("visible", questionData.value) ? questionData.value.visible : true;
});
const showRequiredIndicator = computed(() => {
  if (user.value && !check(Permission.WRITE_ANSWERS, user.value)) {
    return false;
  }
  return (
    !isResponderContext &&
    (!editMode.value || showRequiredIndicatorForTypes.value) &&
    (questionData.value.required || questionData.value.requiredForSave) &&
    // sometimes it's answered but computeProps don't like the answer
    isNoAnswerTypeComputed.value &&
    showUnansweredRequired.value &&
    questionData.value.type !== QuestionType.REFERENCE_TABLE_WITHOUT_SELECTION &&
    questionData.value.type !== QuestionType.ADDRESS // address handles this internally
  );
});
const validationState = computed(() => {
  return getStore().state?.cancelSaveValidationStates?.[questionData.value.key];
});
const showRequiredIndicatorForTypes = computed(() => {
  return (
    questionData.value.type === QuestionType.TAGS ||
    questionData.value.type === QuestionType.IMAGE ||
    questionData.value.type === QuestionType.OBSCURED
  );
});

const questionInfoPaneData = injectStrict(QuestionInfoPaneDataKey, ref(null));
const isRequiredAndHighlight = computed(() => {
  return questionData.value.required && !isAnswered.value && questionInfoPaneData.value?.data?.data?.requireAnswers;
});

// METHODS
const handleInputUpdate = (newValue: any) => {
  emit("update:modelValue", newValue);
  if (questionData.value?.parent?.type === GroupType.GROUP_INSTANCE) {
    globalEmit("questions:reRunGroupValidationState", {
      groupInstance: questionData.value.parent.key,
    });
  }
};
const handleUpdateValidationState = (newState: ValidationSummary) => {
  const validationState: ValidationState = {
    state: newState?.passes,
    forceUpdate: newState.forceUpdate,
    message: newState?.details?.message || "",
    questionKey: questionData.value.key,
  };
  emit("updateValidationState", validationState);
  emit("updateValidState", newState?.passes);
};
const onMouseOver = () => {
  if (editMode.value || isLabelInputType(questionData.value) || hideActions.value) {
    return;
  }
  highlighted.value = true;
};
const timeout = ref(null);
const delayedMouseLeave = () => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  timeout.value = setTimeout(() => {
    if (focused.value) {
      return;
    }
    onMouseLeave();
  }, 50);
};
const onMouseLeave = () => {
  highlighted.value = false;
};
const onPopoverShowChange = (shown) => {
  popoverShown.value = shown;
};
const actionClickHandler = (action: ElementAction) => {
  if (action.standardClickHandler) {
    emit("standardElementAction", action.standardClickHandler);
  } else {
    action.clickHandler();
  }
};
const onFocus = () => {
  focused.value = true;
  emit("focus");
};
const onBlur = () => {
  focused.value = false;
  emit("blur");
};

const clearValidationResults = () => {
  if (questionData.value.validationError || !isNil(validationState.value?.state)) {
    logger.trace(() => `reset validation for ${questionData.value.key}`, validationState.value);
    const validationResults: ValidationSummary = {
      passes: null,
      details: {},
    };
    handleUpdateValidationState(validationResults);
  }
};

watch(showReadOnlyViewModeWhenInEditMode, () => {
  if (showReadOnlyViewModeWhenInEditMode.value) {
    clearValidationResults();
  }
});

// LIFECYCLE
onBeforeUnmount(clearValidationResults);

componentGlobalOn("floatingLeftPane:show", ({elementId, show}) => {
  if (elementId === `element_${questionData.value._id}`) {
    isBeingPointedTo.value = show;
  }
});
</script>

<style lang="less">
@import "@/less/global.less";
@import "SharedQuestionStyling";

@basicInputHeightSmall: 24px;
.question-input-wrap {
  position: relative;
  padding-top: 5px;
  font-size: @font14;
  border: 1px solid transparent;

  .less-than-768({
    font-size: @font12;
  });

  &.hide-label {
    padding-top: 0;
  }

  &.using-element-actions {
    padding-bottom: 10px;
    padding-right: 70px;
  }

  &.question-type-info {
    padding: 0 !important;
  }

  &.question-type-divider {
    padding: 0 !important;
  }

  &.validation-not-passing {
    .form-control {
      border-color: @red300;
    }
  }

  &.view-mode {
    &:focus {
      .primary-focus();
    }
  }

  &.unverified {
    .view-answer {
      background-color: @red100;
      border-left: 2px solid @red400 !important;
      padding: 4px 8px;
    }
  }

  label.question-label {
    position: relative;
    top: 0;
    left: 0;
    transition: 0.1s ease all;
    font-size: @font14;
    opacity: 1;
    margin-bottom: 2px;

    .info-icon {
      color: @primary;
    }

    .less-than-768({
      font-size: @font12;
    });

    .not-answered-required {
      color: #f37b53;
    }
  }

  .view-no-answer {
    font-weight: 500;
  }

  .sub-question-label {
    .not-answered-required {
      color: #f37b53;
    }
  }

  .common-input-styling {
    color: @primaryText;

    .less-than-768({
      font-size: @font12;
    });
  }

  .input-group-prepend,
  .input-group-append {
    height: @basicInputHeight;
    border: 2px solid #eaecf1;

    .input-group-text {
      border-width: 0;
      border-radius: 0;
      background-color: #eaecf1;

      .less-than-768({
        font-size: @font12;
      });
    }
  }

  .form-control {
    .common-input-styling;
    border-radius: 2px;
    border: 2px solid @dividerLight;
    //padding-left: 12px;
    padding-right: 12px;
    background-image: none;

    &:focus {
      outline-color: transparent !important;
      outline-style: none !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }

    &.is-valid {
      &:focus {
        border-color: @dividerLight;
      }
    }
  }

  .view-answer {
    .common-input-styling;
    min-height: unset;
    border-color: transparent;
    word-break: break-word;

    .less-than-768({
      min-height: @basicInputHeightSmall;
      font-size: @font12;
    });
  }

  .history-last-update {
    display: flex;
    margin-top: 30px;

    .last-updated-by {
      flex: 1;
      font-size: @font15;
      font-weight: 600;
    }

    .last-updated-at {
      flex: 1;
      color: #6e6e6e;
      font-size: @font12;
    }
  }

  .read-only-wrap {
    position: relative;
    border: @basicInputBorderWidth solid @grey300;
    background-color: @grey200;
    border-radius: 6px;
    background-image: none;

    .tags-wrap {
      &.view-answer {
        min-height: @basicInputHeight - 2px;
        height: unset;
      }
    }

    .view-answer {
      min-height: @basicInputHeight - 2px;
      margin: 0 !important;
      padding: 0.5rem 0.5rem;
      font-size: @font16;
      font-weight: 400;

      overflow-x: hidden;
      text-overflow: ellipsis;

      .less-than-768({
        font-size: @font12;
      });
    }

    .not-answered {
      margin-bottom: 0;
    }
  }

  .ellipsis-with-actions-wrap.question-actions {
    position: absolute;
    right: 22px;
    top: 21px;
    display: none;
  }

  .loading-indicator {
    position: absolute;
    left: 12px;
    top: 40px;

    font-size: 20px;
  }

  &.show-element-actions {
    &.highlighted {
      .ellipsis-with-actions-wrap.question-actions {
        display: inline-block;
      }
    }

    .has-element-actions {
      .ellipsis-with-actions-wrap.question-actions {
        display: inline-block;
      }
    }

    .read-only-wrap {
      margin-right: 25px;
    }
  }

  &.show-element-actions-always {
    .question-actions {
      display: inline-block;

      .ellipsis-icon-wrap {
        .click-target {
          .ellipsis-icon {
            color: @graphiteBlue !important;
          }
        }
      }
    }
  }

  .unanswered-required {
    position: absolute;
    left: 0;
    padding: 5px 7px;
    bottom: 20px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
    font-weight: 700;
    background-color: @requiredBg;
    color: @requiredFont;
    font-size: @font11;

    &.must-answer {
      background-color: @mustAnswerBg;
      color: @mustAnswerText;
    }
  }

  .invalid-message {
    color: @red500;
    font-size: @font12;
    font-weight: 500;
    margin: 2px;

    &.focused {
      color: @softError;
    }
  }
}

.editing {
  .question-input-wrap {
    .question-actions {
      display: none;
    }
  }
}

.pg-compact-form {
  @inputHeight: 30px;
  .question-input-wrap {
    padding: 2px 0 !important;

    .form-control,
    .select-input-wrap,
    .read-only-wrap,
    .locked,
    .not-answered {
      margin-left: 0 !important;
    }

    .label-content {
      font-size: @font14;
    }

    // Select
    .pg-select,
    .pg-user-select {
      .avatar-image,
      .avatar-initials {
        height: 20px !important;
        width: 20px !important;
      }

      .multiselect__tags {
        font-size: @font14;
        padding-left: 9px;
        border: 1px solid #ced4da;
        height: @inputHeight;

        .multiselect__single,
        .multiselect__input,
        .pg-user-select,
        .multiselect__placeholder {
          // .sharedStyling;
          padding: 3px 0;
          font-size: @font14;
          border-bottom-width: 0;
        }
      }

      .multiselect__content-wrapper {
        font-size: @font14;
      }
    }
  }
}
</style>
