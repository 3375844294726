export enum JobQueue {
  SPRINT = "jobs.sprint",
  JOG = "jobs.jog",
  LONG = "jobs.long",
  WAREHOUSE = "jobs.warehouse",

  VERSION = "jobs.version",
  TASKS_PROFILE = "tasks.profile",
  TASKS_PROFILE_PRIORITY = "tasks.profile.priority",
  CALENDAR = "calendar",
  SEARCH_INDEX = "search.index",
  SEARCH_INDEX_LONG = "search.index.long",
  REVIEWS = "reviews",
  EXPORT = "export",
  BULK_IMPORT = "bulk.import",
  WEBHOOK_SEND = "webhook.send",
  QUESTIONS_LOAD = "questions.load",
  RAW_EXPORT = "raw.export",
  BULK_TIN_UPDATE = "bulk.tin.update",
  STATS = "stats",
  TOPIC_HIDDEN = "topic_hidden",
  GIACT_MIGRATION = "giact_migration",
  OFAC_DAILY_JOB = "ofac_daily_job",
}

export enum ChangeQueue {
  EVENTS = "changes.events",
}

export enum Topic {
  VERSION = "version",
  TASKS = "task",
  CONNECTIONS = "connections",
  ANSWERS = "answers",
  KIT_ANSWERS = "kit_answers",
  THREADS = "threads",
  MESSAGES = "messages",
  MESSAGE_COUNTS = "message_counts",
  EMAIL_LOGS = "email_logs",
  QUESTIONS = "questions",
  VALUE_SETS = "value_sets",
  LISTS = "lists",
  NOTIFICATIONS = "notifications",
  JOB_PROGRESS = "job_progress",
  INFO = "info",
  USER_ROLES = "user_roles",
  ROLES = "roles",
  ACTION_PLAN = "action_plan",
  SERVICE_CONFIG = "service_config",
  APPROVALS = "approvals",
  INTERNAL_BADGES = "internal_badges",
  LOG_OPTIONS = "log_options",
  CLIENT_VERSION = "client_version",
  VALIDATIONS = "validations",
  USER_SIGNUP = "user_signup",
}

export enum TaskProfileChangeType {
  CONNECTION = "connection",
  ANSWERS = "answers",
  REPROCESS = "reprocess",
  TASK_COMPLETION = "task_completion",
  TASK_CREATION = "task_creation",
  TASK_DELETION = "task_deletion",
  TOPIC_REVIEW_REQUEST = "topic_review_request",
  CAMPAIGN = "campaign",
  TASK_REASSIGNMENT = "task_reassignment",
}

export enum ChangeType {
  INSERT = "insert",
  UPDATE = "update",
  DELETE = "delete",
  REFERENCE = "reference",
  INSERTED_REFERENCE = "inserted_reference",
  UPDATED_REFERENCE = "updated_reference",
  NEW_REFERENCE = "new_reference",
}

export enum WebSocketServerEvent {
  AUTHENTICATE = "authenticate",
  JOIN = "join",
  JOINED = "joined",
  LEAVE = "leave",
  LEFT = "left",
  AUTHENTICATED = "authenticated",
  UNAUTHORIZED = "unauthorized",
  API_VERSION_INFO = "apiVersionInfo",

  GET_USER_TASKS = "getUserTasks",
  GET_CONNECTION_TASKS = "getConnectionTasks",
}

export enum Priority {
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
}

export enum JobType {
  ENVIRONMENT_EXPORT = "environment_export",
  INDEX_RESPONDER_SEARCH = "index_responder_search",
  UPDATE_TASK_ASSIGNMENTS = "update_task_assignments",
  UPDATE_TASK_TITLE = "update_task_title",
  UPDATE_SWIFT = "update_swift",
  UPDATE_BADGE = "update_badge",
  UPDATE_BADGE_ON_CONNECTIONS = "update_badge_on_connections",
  BULK_OPERATION_PREVIEW = "bulk_operation_preview",
  BULK_OPERATION_SUBMIT = "bulk_operation_submit",
  REPORT_EXPORT = "report_export",
  CLEAN_INDEX = "clean_index",
  AUDIT_INDEX = "audit_index",
  RECALCULATE_SLAS = "recalculate_slas",
  EXTERNAL_INTERFACE_RUN = "external_interface_run",
  DEBUG_TRIGGERS = "debug_workflow",
  DEBUG_BATCH_JOB = "debug_batch_job",
  RUN_SEARCH_INDEX = "run_search_index",
  BULK_DELETE_CONNECTIONS = "bulk_delete_connections",
  RAW_EXPORT = "raw_export",
  BANK_DOC_VALIDATION = "bank_doc_validation",
  BULK_USER_IMPORT = "bulk_user_import",
  BULK_TASK_PROCESSING = "bulk_task_processing",
  AUTOLINK_BITSIGHT_DATA = "autolink_bitsight_data",
  RUN_CONNECTION_GIACT_DATA = "run_connection_giact_data",
  RECALCULATE_CONNECTION_VISIBLE_FLAGS = "recalculate_connection_visible_flags",
  REFRESH_MASTER_DATA_SHARING = "refresh_master_data_sharing",
  QUESTIONS_LOAD = "questions_load",
  RESET_TOPIC_ASSIGN = "reset_topic_assign",
}

export enum ReportType {
  PENDING_VALIDATIONS_REPORT = "pending_validations_report",
  AGING_VALIDATIONS_REPORT = "aging_validations_report",
  COMPLETED_VALIDATION_TASKS_REPORT = "completed_validation_tasks_report",
  W8_FORM_REPORT = "w8_form_report",
  EVIDENCE_REPORT = "evidence_report",
  GRAPHITE_EVIDENCE_REPORT = "graphite_evidence_report",
  INVITE_NOT_ACCEPT = "invite_not_accept",
  DUPLICATE_TAX_ID = "duplicate_tax_id",
  USERS_PROFILE_SETTINGS = "users_profile_settings",
  ON_HOLD_VALIDATION_NO_BANK_APPROVAL = "on_hold_validation_no_bank_approval",
  SUPPLIERS_WITH_ONE_USER = "suppliers_with_one_user",
  GRAPHITE_VALIDATION_REPORT = "graphite_validation report",
  USER_HISTORY_REPORT = "user_history_report",
  USER_EXPORT = "user_export",
  GRAPHITE_COMPANIES_REPORT = "graphite_companies_report",
  NONNETWORK_COMPANIES_REPORT = "nonnetwork_companies_report",
  RESCREEN_REPORT = "rescreen_report",
  BANK_APPROVAL_EVIDENCE_REPORT = "bank_approval_evidence_report",
  ONE_TIME_SYNC_REPORT = "one_time_sync_report",
  HOURLY_VALIDATIONS = "hourly_validations",
  DIVERSITY_REPORT = "diversity_report",
  NO_CONNECTIONS_REPORT = "no_connections_report",
  GRAPHITE_ROLE_AUDIT = "graphite_role_audit",
  COLUMBIA_CONTRACTS = "columbia_contracts",
  REDIRECT_ENTITY_REPORT = "redirect_entity_report",
  BLOCKING_WITHOUT_PENDING_VALIDATIONS = "blocking_without_pending_validations",
  VALIDATIONS_WITHOUT_VALIDATION_TASK = "validations_without_validation_task",
}
